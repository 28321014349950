const authorize = (options = {}) => {
  const { GATEWAY_URL, DEFAULT_API_ERROR } = options;

  /**
   * Gets fake authorization for one day.
   * Has been used before active authorization. Now is DEPRECATED
   * @deprecated since email and google authorization are available
   */
  const fakeAuth = async () => {
    const url = new URL(`${GATEWAY_URL}/login/authorizeFake`);

    const options = {
      mode: "cors",
      credentials: "include",
      method: "GET"
    };

    try {
      const response = await fetch(url, options),
        statusCode = response.status,
        body = await response.json();

      if (body && body.error && typeof body.error === "string")
        body.error = { message: body.error };

      if (!response.ok)
        throw {
          statusCode,
          message: DEFAULT_API_ERROR,
          ...body.error,
          rawBodyTaken: body
        };
      if (body && body.error)
        throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

      return body;
    } catch (error) {
      throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
    }
  };

  /**
   * Authorize with email and password
   * @param {String} email - An email to login with
   * @param {String} pswd - Password to login with
   * @returns {Promise} result of authorization
   */
  const email = async (email, pswd) => {
    if (!email) throw new Error("Please specify email address");
    if (!pswd) throw new Error("Please specify password");

    const url = new URL(`${GATEWAY_URL}/login/authmail`);

    const options = {
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include",
      method: "POST",
      body: JSON.stringify({ email, pswd })
    };

    try {
      const response = await fetch(url, options),
        statusCode = response.status,
        body = await response.json();

      if (body && body.error && typeof body.error === "string")
        body.error = { message: body.error };

      if (!response.ok)
        throw {
          statusCode,
          message: DEFAULT_API_ERROR,
          ...body.error,
          rawBodyTaken: body
        };
      if (body && body.error)
        throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

      return body;
    } catch (error) {
      throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
    }
  };


  const newCustomerSignup = dataAccount => {
    return new Promise((resolve, reject) => {
      let url = new URL(`${GATEWAY_URL}/login/signup`, window.location.origin);

      let signUpData = {
        email: String(dataAccount.email).trim(),
        first_name: String(dataAccount.firstName).trim(),
        last_name: String(dataAccount.lastName).trim(),
        user_language: "English",
        pswd: String(dataAccount.password).trim(),
        cpswd: String(dataAccount.confirmPassword).trim()
      };
      console.log("signUpData", signUpData);

      fetch(url, {
        mode: "cors",
        headers: {
          "Content-Type": "application/json"
        },
        credentials: "include",
        method: "POST",
        body: JSON.stringify(signUpData)
      })
        .then(response => {
          if (!response.ok) {
            console.log("Status while signup for customer", response.status);
            throw new Error(
              `An unexpected error occurred, please try again in a moment`
            );
          }
          return response.json();
        })
        .then(json => {
          if (json.error) return reject(json.error);

          window.analytics.track("SignUp Started", {
            Reg_Type: "email",
            Platform: "Architect"
          });

          resolve(json.customer || {});
        })
        .catch(e => {
          reject(e);
        });
    });
  };

  let validateEmail = dataAccount => {
    return new Promise((resolve, reject) => {
      let url = new URL(
        `${GATEWAY_URL}/login/checkmail`,
        window.location.origin
      );

      url.searchParams.append("email", dataAccount.email);

      fetch(url, {
        credentials: "same-origin",
        method: "get"
      })
        .then(response => {
          if (!response.ok) {
            console.log("Status while checking email", response.status);
            throw new Error({
              message: `An unexpected error occurred, please try again in a moment`
            });
          }
          return response.json();
        })
        .then(json => {
          console.log('Json', json)
          if (json.validationEmailError)
            window.analytics.track("Email Verification Result", {
              ...window.flattenObject(json.validationResult || {}),
              Platform: "Architect"
            });

          if (json.error) return reject(json.error);

          if (json.customerIDtoCheck && !dataAccount.resetPassword)
            return reject({
              existsCustomer: true,
              message:
                "Customer with this email already exists, please choose another one"
            });

          if (json.customerIDtoCheck && dataAccount.resetPassword)
            return resolve({
              existsCustomer: true
            });

          window.analytics.track("Email Verification Result", {
            ...window.flattenObject(json.validationResult || {}),
            Platform: "Architect"
          });

          return resolve(json);
        })
        .catch(e => {
          reject(e);
        });
    });
  };

  /**
   * Reset password for customer
   * @param {String} email - email to reset pasword
   * @returns {Promise} object with url for Google authorization
   */
  const resetPassword = async email => {
    const url = new URL(`${GATEWAY_URL}/login/forgot`);

    const options = {
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include",
      method: "POST",
      body: JSON.stringify({ email: email })
    };

    try {
      const response = await fetch(url, options),
        statusCode = response.status,
        body = await response.json();

      if (body && body.error && typeof body.error === "string")
        body.error = { message: body.error };

      if (!response.ok)
        throw {
          statusCode,
          message: DEFAULT_API_ERROR,
          ...body.error,
          rawBodyTaken: body
        };
      if (body && body.error)
        throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

      return body;
    } catch (error) {
      throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
    }
  };

  /**
   * Set new password for customer
   * @param {String} newPassword - new password to set to customer
   * @param {String} customerID - customer ID
   * @returns {Promise} object with url for Google authorization
   */
  const setNewPassword = async (customerID, newPassword) => {
    const url = new URL(`${GATEWAY_URL}/login/np`);

    const options = {
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include",
      method: "POST",
      body: JSON.stringify({ pswd: newPassword, customerID: customerID })
    };

    try {
      const response = await fetch(url, options),
        statusCode = response.status,
        body = await response.json();

      if (body && body.error && typeof body.error === "string")
        body.error = { message: body.error };

      if (!response.ok)
        throw {
          statusCode,
          message: DEFAULT_API_ERROR,
          ...body.error,
          rawBodyTaken: body
        };
      if (body && body.error)
        throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

      return body;
    } catch (error) {
      throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
    }
  };

  /**
   * Check user session
   * @param {Object} params - Params to handle on new session creation
   * @returns {Promise} object with url for Google authorization
   */
  const checkIfLoggedIn = async () => {
    const url = new URL(`${GATEWAY_URL}/login/check`);

    const options = {
      mode: "cors",
      credentials: "include",
      method: "GET"
    };

    try {
      const response = await fetch(url, options),
        statusCode = response.status,
        body = await response.json();

      if (body && body.error && typeof body.error === "string")
        body.error = { message: body.error };

      if (!response.ok)
        throw {
          statusCode,
          message: DEFAULT_API_ERROR,
          ...body.error,
          rawBodyTaken: body
        };
      if (body && body.error)
        throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

      return body;
    } catch (error) {
      throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
    }
  };
  return {
    setNewPassword,
    resetPassword,
    checkIfLoggedIn,
    fakeAuth,
    email,
    newCustomerSignup,
    validateEmail
  };
};

export default authorize;
