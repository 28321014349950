import { BotAgentType } from '../../types/agent';
import { ApiConfig } from '../config';
import utils from '../utils';
import { GetAgentsResult } from './agents.types';

const agents = (options: ApiConfig) => {
    const { GATEWAY_URL, DEFAULT_API_ERROR, ARCHITECT_URL, LIVECHAT_SERVICE_URL } = options;
    /**
     * Gets an agent by id
     * @param {string} agentID - Id of the agent to take
     * @param {Object, string} [fields] - Projection of the fields to return
     * @returns {Promise} taken agent
     */
    const getById = async (agentID: string, fields?: Record<string, true | 1> | string): Promise<BotAgentType> => {
        if (!agentID) throw new Error('Please specify agent id');

        const url = new URL(`${GATEWAY_URL}/api/agents/${agentID}`);

        if (fields) {
            if (typeof fields === 'string') url.searchParams.append('fields', fields);
            else url.searchParams.append('fields', utils._parseFieldsObjectToString(fields));
        }

        const options: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            method: 'GET',
            redirect: 'follow',
        };

        try {
            const response = await fetch(url.toString(), options),
                statusCode = response.status,
                body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok)
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    /**
     * Gets list of agents
     * @param {Object} settings - Settings to get agents by (sort, limit, skip, etc.)
     * @param {Object, String} fields - Projection of the fields to return
     * @returns {Promise<Array>} list of agents by settings
     */
    const get = async (settings: object = {}, fields?: object | string): Promise<GetAgentsResult> => {
        const url = new URL(`${GATEWAY_URL}/api/agents`);
        Object.keys(settings).forEach((key) => url.searchParams.append(key, settings[key]));

        if (fields) {
            if (typeof fields === 'string') url.searchParams.append('fields', fields);
            else url.searchParams.append('fields', utils._parseFieldsObjectToString(fields));
        }

        const options: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            method: 'GET',
        };

        try {
            const response = await fetch(url.toString(), options),
                statusCode = response.status,
                body = await response.json();
            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok)
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    /**
     * Updating agent with specified id
     * @param {Object} agent - Data to update ("_id" field is required)
     * @returns {Promise} Updated agent
     */
    const save = async (agent: any = {}): Promise<{ data: BotAgentType }> => {
        if (!agent._id) throw new Error('Please specify "_id" field');

        const url = new URL(`${GATEWAY_URL}/api/saveAgent`);
        const options: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(agent),
        };

        try {
            const response = await fetch(url.toString(), options),
                statusCode = response.status,
                body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok)
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };
            (window as any).analytics.track('Bot Edited', {
                Platform: 'Architect',
                Bot_Name: agent.title,
            });
            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    /**
     * Saves agents raw json
     * @param {String} agentID - Id of an agent to save raw json for
     * @param {String} rawJSON - Raw json stringified object
     * @returns {Promise} an error if occurred while saving raw json
     */
    const saveRawJson = async (agentID: string, rawJSON: string): Promise<any> => {
        if (!agentID) throw new Error('Please specify agent id to save raw json for');

        const url = new URL(`${GATEWAY_URL}/api/saveAgentsRawJson`);

        const options: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({ agentID, rawJSON }),
        };

        try {
            const response = await fetch(url.toString(), options),
                statusCode = response.status,
                body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok)
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    /**
     * Saves agents flow json
     * @param {String} agentID - Id of an agent to save flow json for
     * @param {Object} flowJSON - Flow json stringified object
     * @returns {Promise} an agent with new flow saved
     */
    const saveFlowJson = async (agentID: string, flowJSON: object): Promise<any> => {
        if (!agentID) throw new Error('Please specify agent id to save flow json for');

        const url = new URL(`${GATEWAY_URL}/api/saveAgentsFlowJson`);

        const options: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({
                agentID,
                flowJSON: JSON.stringify(flowJSON),
            }),
        };

        try {
            const response = await fetch(url.toString(), options),
                statusCode = response.status,
                body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok)
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    /**
     * Creates an agent with a fields specified
     * @param {Object} agent - Agent to create
     * @returns {Promise} status and newly created agent
     */
    const create = async (agent: object = {}): Promise<any> => {
        const url = new URL(`${GATEWAY_URL}/api/createAgent`);

        const options: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify(agent),
        };

        try {
            const response = await fetch(url.toString(), options),
                statusCode = response.status,
                body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok)
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    /**
     * Removes an agent
     * @param {String} agentID - Id of the agent to remove
     * @returns {Promise} status of the operation
     */
    const remove = async (agentID: string): Promise<any> => {
        const url = new URL(`${GATEWAY_URL}/api/removeAgent`);
        const options: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({ agentID }),
        };

        try {
            const response = await fetch(url.toString(), options),
                statusCode = response.status,
                body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok)
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    /**
     * Removes agents by specified list of ids
     * @param {Array<String>} agentIDs - An array of agent ids to remove
     * @returns {Promise} an error if occurred while removing agents
     */
    const batchRemove = async (agentIDs: Array<string> = []): Promise<any> => {
        if (!agentIDs || !Array.isArray(agentIDs) || !agentIDs.length)
            throw new Error('Please specify a valid array of agent ids to remove');

        const url = new URL(`${GATEWAY_URL}/api/batchRemoveAgent`);

        const options: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({ agentIDs }),
        };

        try {
            const response = await fetch(url.toString(), options),
                statusCode = response.status,
                body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok)
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    /**
     * Sets specified global variables for the agent
     * @param {String} agentID - Id of the agent to set global variables for
     * @param {Object} variables - An object of variables to set
     * @returns {Promise} an error if occurred while setting global variables
     */
    const setGlobalVariables = async (agentID: string, variables: object = {}): Promise<any> => {
        if (!agentID) throw new Error('Please specify an agent id to set variables for');
        if (!Object.keys(variables).length) throw new Error('Please specify valid variables to set');

        const url = new URL(`${GATEWAY_URL}/api/setGlobalVariables`);

        const options: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({ agentID, variables }),
        };

        try {
            const response = await fetch(url.toString(), options),
                statusCode = response.status,
                body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok)
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    /**
     * Builds agent by id
     * @param {String} agentID Id of the agent to build
     * @returns {Promise} error if occurred while building agent
     */
    const build = async (agentID: string): Promise<any> => {
        if (!agentID) throw new Error('Please specify an agent id to build');

        const url = new URL(`${GATEWAY_URL}/api/rebuildAgent`);

        const options: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({ agentID }),
        };

        try {
            const response = await fetch(url.toString(), options),
                statusCode = response.status,
                body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok)
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    /**
     * Patching agent's skill
     * @param {String} agentID - agent's ID
     * @param {String} skillName - name of the skill to be patched
     * @returns {Promise} Updated agent
     */

    const patchSkill = async (agentID: string, skillName: string): Promise<{ updatedAgent: BotAgentType }> => {
        if (!agentID) throw new Error('Please specify an agent id');
        if (!skillName) throw new Error('Please specify a skill name');

        const url = new URL(`${GATEWAY_URL}/api/${agentID}/patchSkill`);

        const options: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            method: 'PUT',
            body: JSON.stringify({ skill: skillName }),
        };

        try {
            const response = await fetch(url.toString(), options),
                statusCode = response.status,
                body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok)
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    /**
     * Get agent's persistent menu
     * @param {String} agentID - agent's ID
     * @returns {Promise} Updated agent
     */
    const getPersistentMenu = async (agentID: string): Promise<any> => {
        if (!agentID) throw new Error('Please specify an agent id');

        const url = new URL(`${ARCHITECT_URL}/v1/facebook/persistent-menu/`);
        url.searchParams.append('agentId', agentID);
        const options: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            method: 'GET',
        };

        try {
            const response = await fetch(url.toString(), options);
            const body = await response.json();
            if (body.status === 'error') {
                throw body.error ? { message: body.error } : { message: DEFAULT_API_ERROR };
            }
            return body;
        } catch (error) {
            throw error ? error : { message: DEFAULT_API_ERROR };
        }
    };

    /**
     * Save agent's persistent menu
     * @param {String} agentID - agent's ID
     * @param {Array} menu - agent'spersistent menu array
     * @returns {Promise} Updated agent
     */
    const setPersistentMenu = async (agentID: string, menu: Array<any>): Promise<any> => {
        if (!agentID) throw new Error('Please specify an agent id');
        if (!agentID) throw new Error('Please specify an agent menu array');

        const url = new URL(`${ARCHITECT_URL}/v1/facebook/persistent-menu/`);
        url.searchParams.append('agentId', agentID);
        const options: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify({ menu }),
        };

        try {
            const response = await fetch(url.toString(), options);
            const body = await response.json();
            if (body.status === 'error') {
                throw body.error ? { message: body.error } : { message: DEFAULT_API_ERROR };
            }
            return body;
        } catch (error) {
            throw error ? error : { message: DEFAULT_API_ERROR };
        }
    };

    const getCurrentQueueLimit = async (agentID: string) => {
        if (!agentID) throw new Error('Please specify an agent id');
        const url = new URL(`${LIVECHAT_SERVICE_URL}/api/v1/queue-limit?botId=${agentID}`);
        const options: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            method: 'GET',
        };

        try {
            const response = await fetch(url.toString(), options);
            const body = await response.json();
            if (body.status === 'error') {
                throw body.error ? { message: body.error } : { message: DEFAULT_API_ERROR };
            }
            return body;
        } catch (error) {
            throw error ? error : { message: DEFAULT_API_ERROR };
        }
    };

    const setCurrentQueueLimit = async (agentID: string, limit: number) => {
        if (!agentID) throw new Error('Please specify an agent id');

        const url = new URL(`${LIVECHAT_SERVICE_URL}/api/v1/queue-limit?botId=${agentID}&limit=${limit}`);
        const options: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            method: 'PUT',
        };

        try {
            const response = await fetch(url.toString(), options);
            const body = await response.json();
            if (body.status === 'error') {
                throw body.error ? { message: body.error } : { message: DEFAULT_API_ERROR };
            }
            return body;
        } catch (error) {
            throw error ? error : { message: DEFAULT_API_ERROR };
        }
    };

    const getAllTags = async (agentID: string): Promise<{ tags: string[] }> => {
        if (!agentID) throw new Error('Please specify an agent id');

        const url = new URL(`${GATEWAY_URL}/api/agents/${agentID}/tags`);
        const options: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            method: 'GET',
            redirect: 'follow',
        };

        try {
            const response = await fetch(url.toString(), options);
            const body = await response.json();
            if (body.status === 'error') {
                throw body.error ? { message: body.error } : { message: DEFAULT_API_ERROR };
            }
            return body;
        } catch (error) {
            throw error ? error : { message: DEFAULT_API_ERROR };
        }
    };
    const getAllAttributes = async (agentID: string): Promise<{ attributes: string[] }> => {
        if (!agentID) throw new Error('Please specify an agent id');

        const url = new URL(`${GATEWAY_URL}/api/agents/${agentID}/attributes`);
        const options: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            method: 'GET',
            redirect: 'follow',
        };

        try {
            const response = await fetch(url.toString(), options);
            const body = await response.json();
            if (body.status === 'error') {
                throw body.error ? { message: body.error } : { message: DEFAULT_API_ERROR };
            }
            return body;
        } catch (error) {
            throw error ? error : { message: DEFAULT_API_ERROR };
        }
    };

    return {
        getById,
        get,
        save,
        saveRawJson,
        saveFlowJson,
        create,
        remove,
        batchRemove,
        setGlobalVariables,
        build,
        patchSkill,
        getPersistentMenu,
        setPersistentMenu,
        setCurrentQueueLimit,
        getCurrentQueueLimit,
        getAllTags,
        getAllAttributes,
    };
};

export default agents;
