import { CustomerPermission } from '../../types/customerRole';
import { ApiConfig } from '../config';
import { ReadAllRolesInBotResult, ReadCusmoerRolesResult } from './roles.types';

const roles = (options: ApiConfig) => {
    const { DEFAULT_API_ERROR, ROLES_SERVICE_URL } = options;

    /**
     * Create role
     * @param {String} botId - botID в котором создать роль
     * @param {String} customerId - в котором создать роль
     * @param {Object} permissions - обьект с масивом строк(permissions) с которыми создать роль
     */
    const createRoles = async (botId: string, customerId: string, permissions: CustomerPermission[]) => {
        if (!botId || !customerId || !permissions) throw new Error('Incorrect params passed into function call');

        const url = new URL(
            `${ROLES_SERVICE_URL}/api/v1/roles?botId=${botId}&customerId=${customerId}&attachCustomerMeta=true`
        );

        const fetchOptions: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify({ permissions }),
        };

        try {
            const response = await fetch(url.toString(), fetchOptions);
            const statusCode = response.status;
            const body = await response.json();
            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };
    /**
     * Send invite
     * @param {String} roleId -roleId
     * @param {String} inviting_user - inviting_user
     * @param {String} invited_user - invited_user
     * @param {String} bot_name - bot_name
     */
    const sendInvite = async (roleId, inviting_user, invited_user, bot_name) => {
        if (!inviting_user || !invited_user || !bot_name || !roleId)
            throw new Error('Incorrect params passed into function call');

        const url = new URL(`${ROLES_SERVICE_URL}/api/v1/roles/${roleId}/invite`);

        const fetchOptions: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify({ meta: { inviting_user, invited_user, bot_name } }),
        };

        try {
            const response = await fetch(url.toString(), fetchOptions);
            const statusCode = response.status;
            const body = await response.json();

            if (statusCode === 200) return statusCode;
            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };
    /**
     * Read roles of customer in current bot
     * @param {String} botId - botID в котором создать роль
     * @param {String} customerId - в котором создать роль
     */
    const readRoles = async (botId, customerId, attachCustomerMeta = false) => {
        if (!botId || !customerId) throw new Error('Incorrect params passed into function call');

        const url = new URL(
            `${ROLES_SERVICE_URL}/api/v1/roles?botId=${botId}&customerId=${customerId}${
                attachCustomerMeta ? '&attachCustomerMeta=true' : ''
            }`
        );

        const fetchOptions: RequestInit = {
            method: 'GET',
            credentials: 'include',
        };

        try {
            const response = await fetch(url.toString(), fetchOptions);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    const readCustomerRoles = async (customerId: string): Promise<ReadCusmoerRolesResult> => {
        if (!customerId) throw new Error('Incorrect params passed into function call');
        const url = new URL(`${ROLES_SERVICE_URL}/api/v1/roles?customerId=${customerId}`);

        const fetchOptions: RequestInit = { method: 'GET' };

        try {
            const response = await fetch(url.toString(), fetchOptions);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };
    const readAllRolesInBot = async (botId: string, attachCustomerMeta = false): Promise<ReadAllRolesInBotResult> => {
        if (!botId) throw new Error('Incorrect params passed into function call');

        const url = new URL(
            `${ROLES_SERVICE_URL}/api/v1/roles?botId=${botId}${attachCustomerMeta ? '&attachCustomerMeta=true' : ''}`
        );

        const fetchOptions: RequestInit = { mode: 'cors', method: 'GET' };

        try {
            const response = await fetch(url.toString(), fetchOptions);
            const statusCode = response.status;
            const body = await response.json();
            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };
    /**
     * Update role
     * @param {String} botId - botID в котором создать роль
     * @param {String} customerId - в котором создать роль
     * @param {Object} data -  { permissions: [String] OR status: String }
     */
    const updateRole = async (botId, customerId, data) => {
        if (!botId || !customerId || !data) throw new Error('Incorrect params passed into function call');

        const url = new URL(
            `${ROLES_SERVICE_URL}/api/v1/roles?botId=${botId}&customerId=${customerId}&attachCustomerMeta=true`
        );

        const fetchOptions: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            method: 'PUT',
            body: JSON.stringify({ permissions: data }),
        };

        try {
            const response = await fetch(url.toString(), fetchOptions);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };
    const changeStatus = async (botId, customerId, status) => {
        if (!botId || !customerId || !status) throw new Error('Incorrect params passed into function call');

        const url = new URL(
            `${ROLES_SERVICE_URL}/api/v1/roles?botId=${botId}&customerId=${customerId}&attachCustomerMeta=true`
        );

        const fetchOptions: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            method: 'PUT',
            body: JSON.stringify({ status }),
        };

        try {
            const response = await fetch(url.toString(), fetchOptions);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };
    const deleteRole = async (botId, customerId) => {
        if (!botId || !customerId) throw new Error('Incorrect params passed into function call');

        const url = new URL(`${ROLES_SERVICE_URL}/api/v1/roles?botId=${botId}&customerId=${customerId}`);

        const fetchOptions: RequestInit = {
            method: 'DELETE',
        };

        try {
            const response = await fetch(url.toString(), fetchOptions);
            const statusCode = response.status;

            const body = await response.json();

            if (statusCode === 200) return { statusCode };
            if (body && body.error && typeof body.error === 'string') {
                body.error = { message: body.error };
            }

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) {
                throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };
            }

            return statusCode;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    return {
        createRoles,
        readRoles,
        readAllRolesInBot,
        updateRole,
        deleteRole,
        readCustomerRoles,
        changeStatus,
        sendInvite,
    };
};

export default roles;
